.instafeed {
  padding-top: 50px;
  padding-bottom: 97px;
}
.instafeed .instafeed__mens {
  margin-top: 50px;
}
.instafeed .instafeed__heading {
  font-family: "GT Super Ds", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.25;
  color: #2b2b2b;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .instafeed .instafeed__heading:hover {
    opacity: 0.7;
  }
}
.instafeed .instafeed__link-text {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.11;
  letter-spacing: 0.2em;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .instafeed .instafeed__link-text:hover {
    opacity: 0.7;
  }
}
.instafeed .instafeed__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #2b2b2b;
}
.instafeed .instafeed__link svg {
  width: 8px;
  height: 16px;
  margin-left: 18px;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .instafeed .instafeed__link:hover .instafeed__link-text::after {
    opacity: 1;
  }
}
.instafeed .instafeed__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
}
.instafeed .fs-has-links::after {
  content: "SHOP NOW";
  margin: 0;
  padding: 0;
  color: #ffffff;
}
.instafeed .fs-service-icon {
  padding: 0;
}
.instafeed .fs-desktop .fs-entry-container {
  width: 20% !important;
  padding-top: 20% !important;
}
.instafeed .fs-mobile .fs-entry-container {
  width: 50% !important;
  padding-top: 50% !important;
}
.instafeed .fs-wrapper div.fs-text-container .fs-entry-title,
.instafeed div.fs-detail-title {
  font-family: "Nexa Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
}
.instafeed .fs-post-info {
  font-size: 12px !important;
}
.instafeed div.fs-text-container .fs-entry-date,
.instafeed div.fs-detail-container .fs-post-info,
.instafeed div.fs-wrapper div.fs-has-links::after,
.instafeed .fs-text-product,
.instafeed .fs-overlink-text {
  font-family: "Nexa Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}
.instafeed .fs-wrapper div.fs-text-container * {
  color: #ffffff;
}
.instafeed .fs-wrapper div.fs-text-container {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.8);
}
.instafeed div.fs-entry-date {
  display: none;
}
.instafeed div.fs-entry-title {
  display: none;
}
.instafeed .instafeed__mens .fs-wrapper div.fs-timeline-entry {
  background-size: 110%;
}
.instafeed .fs-title {
  border: 0 !important;
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #2b2b2b;
}
.instafeed .fs-subtitle {
  margin-left: 5px;
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.1px;
  text-align: left !important;
  color: #2b2b2b;
}
.instafeed .fs-post-info a,
.instafeed .fs-service-username,
.instafeed .fs-slashes,
.instafeed .fs-detail-date {
  text-transform: lowercase;
  color: #000 !important;
}
.instafeed .foursixty-section {
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: center;
}
.instafeed .fs-slider-next-button,
.instafeed .fs-slider-prev-button {
  z-index: 1;
  opacity: 0.5;
  min-width: 30px;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .instafeed .fs-slider-next-button:hover,
  .instafeed .fs-slider-prev-button:hover {
    opacity: 1;
  }
}
.instafeed .fs-slider-next-button,
.instafeed .fs-slider-prev-button,
.instafeed .instafeed__link,
.instafeed .instafeed__heading {
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.instafeed .fs-slider-next-button .fs-arrow,
.instafeed .fs-slider-prev-button .fs-arrow {
  stroke: #000;
  stroke-width: 2px;
}
.instafeed .fs-add-to-cart-enabled .fs-view a,
.instafeed .fs-add-to-cart-enabled .fs-view a:link {
  font-size: 12px;
  font-weight: 500;
}
.instafeed .fs-shopify-add-cart.fs-shopify-add-cart-container {
  padding: 12px 15px;
  max-width: 120px;
  font-size: 11px;
  color: #ffffff;
}
.instafeed .fs-button-bar .fs-buy-button {
  font-family: inherit;
  font-size: 13px;
  color: #ffffff;
}
.instafeed .fs-option-label {
  font-family: inherit;
  font-size: 13px;
}
.instafeed .fsbtn {
  padding: 15px 0;
  text-align: center;
}
.instafeed .fs-desktop .fs-timeline-entry .fs-text-container:focus-visible {
  opacity: 1;
}
@media (max-width: 1199px) {
  .instafeed {
    padding: 70px 0;
  }
}
@media (max-width: 991px) {
  .instafeed {
    padding: 50px 0;
  }
}
@media (max-width: 767px) {
  .instafeed .fs-timeline {
    max-width: initial;
    margin: 0;
  }
  .instafeed .fs-slider-next-button,
  .instafeed .fs-slider-prev-button {
    display: none;
  }
  .instafeed .fs-slider-next-button .fs-arrow,
  .instafeed .fs-slider-prev-button .fs-arrow {
    stroke: #2b2b2b;
    stroke-width: 2px;
  }
  .instafeed .fs-wrapper div.fs-entry-container {
    width: 75% !important;
    height: 0;
    padding-top: 75% !important;
  }
  .instafeed .instafeed__header {
    margin-bottom: 20px;
  }
  .instafeed .instafeed__link svg {
    width: 8px;
    height: 12px;
    margin-left: 6px;
  }
  .instafeed .instafeed__link-text {
    font-size: 16px;
  }
  .instafeed .fs-slider-container {
    width: auto;
    margin-right: -30px;
    max-width: none;
  }
}
@media (max-width: 575px) {
  .instafeed {
    padding-top: 15px;
    padding-bottom: 38px;
  }
  .instafeed .instafeed__header {
    margin-bottom: 15px;
  }
  .instafeed .instafeed__heading {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
  }
  .instafeed .instafeed__link-text {
    font-size: 10px;
    line-height: 1.333;
  }
  .instafeed .instafeed__link svg {
    width: auto;
    height: 10px;
    margin-left: 6px;
  }
  .instafeed .fs-slider-container {
    margin-right: -15px;
  }
}
@media (max-width: 480px) {
  .instafeed .instafeed__mens {
    margin-top: 25px;
  }
}
@media (max-width: 380px) {
  .instafeed .instafeed__header {
    margin-bottom: 14px;
  }
}